
.drag-target {
  background: none;
}

.drag-ghost {
  // border-left: 2px solid rgba(100, 200, 255, 0.5);
  // background-color: rgba(173, 238, 255, 0.5);
  // padding-top: 1rem;
  // padding-bottom: 0rem;
  opacity: 0.3;
  background-color: var(--v-info-lighten3);
}

.empty-drag-area {
  min-height: 8px;
  border: 1px dashed var(--v-theme-primary);
}

.empty-drag-area-placeholder {
  min-height: 8px;
  border: 1px solid transparent;
}

.debug-drag-container {
  min-height: 32px;
  border: 1px solid red;
}

.debug-drag-child {
  border: 1px solid green;
}

.draggable-container-level-0 {
  margin: 0;
}

.draggable-container-level-1 {
  margin: 0 0 0 1.5rem;
}

.draggable-container {
  margin: 0 0 0 2.5rem;
}

.draggable-border-left {
  position: relative;

  &:before {
    border-left: 1px solid rgba(5, 66, 113, 0.2);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.draggable-border-left-clipped {
  position: relative;

  &:before {
    border-left: 1px solid rgba(5, 66, 113, 0.2);
    content: '';
    position: absolute;
    top: 0;
    bottom: 3px;
  }
}
