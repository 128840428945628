@use 'vuetify/lib/styles/tools/functions' as *;
@use 'vuetify/settings' as *;

$prima-font-size-root: 10pt !important;
$prima-border-radius: 12px;
$prima-border-radius-small: 6px;


$color-pack: true;
$field-font-size: $prima-font-size-root; // for placeholder/value
$typography: (
    'body-1': (
      'size': 1rem, // this is also used for inputs
      'weight': 400,
    ),
  );
// $input-density: ('default': 0, 'comfortable': -2, 'compact': -4);
$font-size-root: $prima-font-size-root;
$body-font-family: "'Inter', 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important";
$heading-font-family: "'Inter', 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important";

$input-affix-margin-inside: 0.5em;
$dialog-elevation: 15;

// $field-rounded-border-radius: map-get($rounded, 'sm');
$border-radius-root: $prima-border-radius;
$alert-border-radius: $prima-border-radius;
$stepper-border-radius: $prima-border-radius;
$sheet-border-radius: $prima-border-radius;
$dialog-border-radius: $prima-border-radius; // why no work

$button-border-radius: $prima-border-radius-small;
$field-border-radius: $prima-border-radius-small;
$progress-linear-border-radius: $prima-border-radius-small;
$expansion-panel-border-radius: $prima-border-radius-small;

$navigation-drawer-border-thin-width: 0;

$treeview-node-height: 4rem;
$treeview-node-height-dense: 4rem;
$treeview-node-level-width: 18px;
$treeview-node-margin: 0;
$treeview-node-padding: 0;

$progress-linear-background-opacity: 0.3;

$tooltip-font-size: 1rem;

$text-field-outlined-fieldset-border: 1px solid currentColor;
$text-field-outlined-fieldset-border-width: 1px !important;
$data-table-regular-header-font-size: 1rem !important;
$data-table-regular-row-font-size: 1rem !important;
$expansion-panel-content-padding: 0 !important;

$selection-control-size: 32px;
$input-control-height: 24px;

$list-item-min-height: 24px;
$list-item-one-line-min-height: 24px;

$switch-inset-thumb-height: 12px; // 24px
$switch-inset-thumb-off-height: 12px; // 16px
$switch-inset-thumb-off-width: 12px; // 16px
$switch-inset-thumb-width: 12px; // 24px
$switch-inset-track-border-radius: 9999px; // 99px
$switch-inset-track-height: 24px; // 32px
$switch-inset-track-width: 52px; // 52px

// y u no work
$shadow-key-umbra: map-deep-merge(
  (
    1: (0px 2px 5px 0px rgba(0, 0, 0, 0.05)),
  ),
  $shadow-key-umbra
);

$shadow-key-penumbra: map-deep-merge(
  (
    1: (0px 2px 10px 0px rgba(0, 0, 0, 0.02)),
  ),
  $shadow-key-penumbra
);

$shadow-key-ambient: map-deep-merge(
  (
    1: (none),
  ),
  $shadow-key-ambient
);

$field-control-solo-elevation: 1;

$field-outline-opacity: 0.15;

$color-picker-swatch-color-height: 24px;

/* $button-disabled-opacity: 0.1;
$button-disabled-overlay: 0.1; */

// $tooltip-transition-enter-duration: 50ms;
// $tooltip-transition-leave-duration: 50ms;

// $btn-group-height: auto;

// $font-weights: map-deep-merge($font-weights, ('medium': 600));

// Custom component settings
// $input-font-size: $font-size-root !default;
// $input-max-height: 2em !default;
// $input-label-height: 1.25em !default;
// $input-icon-height: 1.5em !default;
// $input-icon-min-width: 1.5em !default;
// $input-icon-width: 1.5em !default;
// $input-slot-margin-bottom: 0.5em !default;
// $input-dense-slot-margin-bottom: 0.25em !default;
// $label-font-size: $font-size-root !default;
// $tab-font-size: 0.8rem !default;
// $alert-font-size: $font-size-root !default;
// $input-top-spacing: 0;



/* $material-light: () !default;
$material-light: map-deep-merge(
  (
    'text': (
      'theme': map-get($shades, 'white'),
      'primary': rgba(map-get($shades, 'black'), 0.87),
      'secondary': rgba(map-get($shades, 'black'), 0.6),
      'disabled': rgba(map-get($shades, 'black'), 0.38),
      'link': map-get($blue, 'darken-2'),
      'link-hover': map-get($grey, 'darken-3'),
    ),
    'icons': (
      'active': rgba(map-get($shades, 'black'), 0.75),
      'inactive': rgba(map-get($shades, 'black'), 0.38),
    ),
  ),
  $material-light
); */
// $main-transition: none;
// $breadcrumbs-item-large-font-size: 14pt;


// @import '~vuetify/styles/main.sass';
// @import '~vuetify/styles';
