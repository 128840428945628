

.repo-table {
  width: 50%;

  &.repo-table-full-width {
    width: 100%;
  }

  &.limit-to-single-section {
    width: 100%;
  }

  & {
    border-collapse: collapse;
  }

  & {
    border-spacing: 0px;
  }

  & {
    table-layout: fixed;
  }

  & tbody > tr > th {
    width: 30%;
    text-align: right;
  }

  &:not(.limit-to-single-section) tbody > tr > th,
  &:not(.limit-to-single-section) tbody > tr > td {
    padding: 4px;
  }
}

