.v-breadcrumbs {
  flex-wrap: wrap;
}

.v-breadcrumbs-item--disabled {
  opacity: 1 !important;
}

.breadcrumb-inactive-text-color {
  color: rgba(32, 32, 32, 0.84) !important;
}

.v-breadcrumbs-item h3 {
  font-size: 1.5rem;
}

.v-breadcrumbs-divider > .v-icon--size-large {
  font-size: 1.25em !important;
}
