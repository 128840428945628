
.die3-ol-list {
  & li {
    margin-bottom: 0.5rem;

    &::marker {
      padding-right: 1rem;
      color: #808080;
      font-size: 14pt;
      font-weight: 600;
    }
  }

  p {
    margin-bottom: 8px; // half of normal
  }
}
