
@import '~suneditor/dist/css/suneditor.min.css';

.toggle-toolbar-button {
  padding: 4px;
  z-index: 10 !important;

  &.active {
    color: rgb(var(--v-theme-primary));
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sun-editor {
  font-family: var(--prima-default-font) !important;
  padding: 0;
  border-radius: var(--prima-border-radius-small);
  border: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: unset !important;

  &.show-toolbar .sun-editor-editable.suneditor-planit {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0 1px 1px 1px;
  }

  &.show-toolbar .se-toolbar {
    display: block !important;
  }
}

.sun-editor-editable.suneditor-planit {
  font-family: var(--prima-default-font) !important;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-radius: var(--prima-border-radius-small);
}

.sun-editor .se-toolbar-inline {
  box-shadow: none !important;
}

.sun-editor.show-toolbar .se-toolbar.se-toolbar-shadow {
  display: none !important;
}

.sun-editor .se-placeholder {
  padding: 8px !important;
  text-overflow: unset !important;
  white-space: unset !important;
  color: rgba(71, 71, 71, 0.6) !important;
}

.sun-editor .se-toolbar {
  background-color: transparent !important;
  outline: none !important;
  display: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-width: 1px 1px 0 1px !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
}

.sun-editor .se-btn-tray {
  border: none !important;
  background-color: white !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
  outline: none !important;
}

.sun-editor .se-btn-module-border {
  border: none !important;
}

.sun-editor .se-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: var(--prima-border-radius-small) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.sun-editor-editable a.on {
  color: var(--v-anchor-base);
  background-color: unset;
}
