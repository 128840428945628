
// .combobox-single-select-custom-item {
//   background-color: #f5f5f5;
//   color: #333;
//   border-radius: 4px;
//   padding: 0 4px;
//   margin-right: 4px;
//   font-size: 0.8rem;
//   line-height: 1.5rem;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
:deep(.v-combobox__selection){
  margin:8px 0px 4px 0px;
  background-color:#333;
  color: #f5f5f5;
  border-radius: 4px;
}
:deep(.v-radio-group .v-input__control) {
  flex-grow: 1;
}
