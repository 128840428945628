@use '@/sass/variables';
@use 'vuetify';

@use '@/sass/inter';
@use '@/sass/lora';

$primary-gradient: linear-gradient(to bottom right, #6fa5ff 0%, #0019a9 100%);
$primary-dark-gradient: linear-gradient(to bottom right, #184faf 0%, #0019a9 100%);

:root {
  --prima-default-font: 'Inter', 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  --v-theme-background: #0057FF;
  --v-theme-on-background: #000000;
  --v-high-emphasis-opacity: 0.87;

  --prima-app-bar-background-gradient: #fff;

  --prima-license-reseller-gradient: linear-gradient(135deg, #f9ad23 0%, #f77d00 100%);
  --prima-license-reseller-color: #4d2600;

  --prima-left-drawer-background-color: #fafafa;
  --prima-right-drawer-background-color: #00358a17;

  --prima-border-radius: #{variables.$prima-border-radius};
  --prima-border-radius-small: #{variables.$prima-border-radius-small};

  --prima-box-shadow:
    0 2px 5px rgba(0, 0, 0, 0.05),
    0 2px 10px rgba(0, 0, 0, 0.02);

  --prima-dialog-box-shadow:
    0 2px 5px rgba(0, 0, 0, 0.05),
    0 2px 10px rgba(0, 0, 0, 0.02),
    0px 0px 48px -8px rgba(0, 0, 0, 0.7);

  --prima-bright-green-accent-color: #A5F4B2;

}

@import 'modules/breadcrumbs';
@import 'modules/draggable';


html {
  font-family: var(--prima-default-font);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.lora-heading {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

// Custom styles for headings
h1, h2, h3, h4, h5, h6, label {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
}

// body {
//   background-color: var(--v-theme-background);
//   color: var(--v-theme-on-background); // Use the onBackground variable
// }

// .v-application {
//   background: var(--v-theme-background);
//   color: var(--v-theme-on-background) !important; // Ensure this is set correctly
// }

// Ensure specific elements use the correct colors
// h1, h2, h3, h4, h5, h6, label {
//   color: var(--v-theme-on-background);
// }

html, body {
  min-height: 100%;
  overflow: auto;
}

table th {
  font-weight: 600;
}

th.th-icon,
td.td-icon {
  width: 32px; // 16px + 8px padding
  padding: 12px 8px !important;
  vertical-align: middle !important;
}

.profile-card {
  background-size: cover;
  background-position: 50% 50%;
  min-height: 7rem;
  color: #fff !important;
  text-shadow: 0px 0px 24px rgba(0, 0, 0, 1), 2px 2px 4px rgba(0, 0, 0, .7);
  background-color: white;
  box-shadow: var(--prima-box-shadow);
  border-radius: var(--prima-border-radius);

  & h2 {
    font-size: 1.5em;
  }

  & .v-icon {
    font-size: 1em;
  }
}

.profile-card-hover {
  position: relative;

  &:hover::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
  }
}

.card-button {
  position: relative;
  cursor: pointer;

  &:hover::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border: 2px solid var(--v-theme-primary);
    border-radius: var(--prima-border-radius);
  }

  &:active::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }
}

.card-link {
  background: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // box-shadow: var(--prima-box-shadow);
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25);
  border-radius: var(--prima-border-radius);
  padding: 2px 8px;
}

.login-sheet {
  // background: rgba(255, 255, 255, 1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
  //background: #f0f0f099 !important;
  backdrop-filter: blur(15px);
  background-blend-mode: lighten !important;

  h2 {
    font-weight: 600;
  }
}

.prima-profile-background {
  background:
    radial-gradient(circle at top left, #00000040 10%, #00000000 60%),
    url('../../public/profile/prima_profile_highlight2.jpeg')
  ;
  background-position: center;
  background-size: 190%;
}

.die3-background {
  background-image: url('../../public/prima_die3.webp');
  background-size: cover;
}

.die3-background-wo-text {
  background-image: url('../../public/prima_die3_wo_text_vignette.webp');
  background-size: cover;
}

.die3-site-background {
  background: radial-gradient(farthest-corner at 50px 50px, #222c3c 0%, #191e17 140%) !important;
}

.login-card-background {
  border-radius: 0 var(--prima-border-radius) var(--prima-border-radius) 0;
}

.partner-k-brand-login-background {
  background-color: #f3f4f6;

  & .login-card {
    box-shadow: none;
    border: 1px solid #c4cbd4;
    border-radius: var(--prima-border-radius);
  }

  & .login-card-background {
    border-radius: 0 var(--prima-border-radius) var(--prima-border-radius) 0;
    background-color: #0d5b8c !important;
    background-image: none;
  }
}

.selected-company + input {
  display: none !important;
}

.v-app-bar.bg-transparent, .v-app-bar.bg-transparent > .v-toolbar__content {
  box-shadow: none !important;
}

.v-app-bar
  .theme--dark.v-text-field--filled
  > .v-input__control
  > .v-input__slot:hover,
.navbar-select-hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

input::placeholder {
    color: #ddd;
    opacity: 1 !important;
  }

.v-app-bar
  .theme--dark.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0;

  & input::placeholder {
    color: #ddd;
    opacity: 1 !important;
  }

  &::after {
    display: none;
  }

  & .v-input__append-inner {
    margin-top: unset !important;
    align-self: center !important;
  }

  & .v-input__icon {
    width: 0.8rem !important;
    min-width: 0.8rem !important;
    height: 0.8rem !important;

    & > .v-icon {
      font-size: 1.25rem !important;
    }
  }
}

code {
  color: #333;
}

p {
  word-break: break-word;
}

label .markdown-container {
  hyphens: unset;

  p {
    word-break: unset;
    hyphens: unset;
  }
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.translateTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;

  table-layout: fixed;

  tbody:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  td, th {
    padding: 8px;
  }

  .deepl-logo {
    max-width: 100%;
    height: auto;
    width: 2rem;
  }
}

.uploadinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(5, 66, 113, 0.2) !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot:hover
  fieldset {
  color: rgba(5, 66, 113, 0.3) !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(5, 66, 113, 0.2) !important;
}

.v-select.select-remove-spacer > .v-input__control > .v-input__slot {
  cursor: pointer !important;

  & .v-select__selections {
    width: 120% !important;
    & > input {
      display: none !important;
    }

    & > .v-select__selection--comma {
      overflow: unset !important
    }
  }
}

.theme--light.v-chip.v-chip--label:hover::before {
  opacity: 0 !important;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.75) !important;
}

// .theme-dark.v-btn.text-primary,
// .theme-dark.text-primary,
// .v-application.theme-dark.text-primary,
// .v-item-group.theme-dark.text-primary,
// .v-list-item.theme-dark .v-list-item__content {
//   color: #fff !important;
// }

.v-list-item .v-list-item__subtitle {
  line-height: 1.3;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left-004 {
  border-left: 1px solid rgba(0, 0, 0, 0.04);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-all {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.border-primary {
  border-color: rgb(var(--v-theme-primary));
}

.border-success {
  border-color: rgb(var(--v-theme-success));
}

.border-error {
  border-color: rgb(var(--v-theme-error));
}

.v-content {
  padding-top: 64px;
}

.v-dialog > .v-overlay__content {
  border-radius: var(--prima-border-radius) !important;
}

.select-group-modal {
  width: 60vw !important;
  min-width: min(80rem, 100vw);
  background-color: #fff;

  border-radius: var(--prima-border-radius) !important;
  margin: 1rem !important;
  height: 100%;
  position: fixed;
  overflow-y: auto !important;
  top: 0;
  left: unset;
  right: 0;
  max-height: calc(100% - 2rem) !important;

  &.modal-40 {
    width: 40vw !important;
    min-width: 60rem !important;
  }

  &.modal-30 {
    width: max(60rem, 30vw) !important;
    min-width: 60rem !important;
  }
}

.select-group-modal .translateTable tbody {
  border-bottom: none;
}

.table-settings-modal {
  width: 40vw !important;
  min-width: 50rem;
  background-color: #fff;

  border-radius: 0.5rem !important;
  margin: 1rem !important;
  height: 100%;
  position: fixed;
  overflow-y: auto !important;
  top: 0;
  left: unset;
  right: 0;
  max-height: calc(100% - 2rem) !important;

  &.modal-40 {
    width: 40vw !important;
    min-width: 60rem !important;
  }
}

.table-settings-modal .translateTable tbody {
  border-bottom: none;
}


.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.default-font-size {
  font-size: 10pt !important;
  // font-weight: 500;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.scrollbar {
  /* scrollbar-color: #ccc #f1f1f1; */
  // scrollbar-color: #ccc transparent;
  scrollbar-width: 0.5rem;
  background-clip: padding-box;

  // overscroll-behavior-y: contain;
}

.scrollbar-hover, .v-navigation-drawer__content {
  &.scrollbar-visible {
    padding-right: 0.5rem;
  }

  &::-webkit-scrollbar {
    display: none;
    background-clip: padding-box;
  }

  &:hover {
    padding-right: 0;
  }

  &:hover::-webkit-scrollbar {
    display: initial;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.admin-dashboard-active {
  background-color: var(--v-error-darken3) !important;
  color: #fff;
}

.admin-dashboard-active::before {
  background-color: var(--v-error-darken3) !important;
  opacity: 1 !important;
}

.v-treeview .v-list-item__content a {
  word-wrap: break-word !important;
  white-space: normal;
}

.v-treeview-item.v-list-item--active {
  & a {
    font-weight: 600 !important;
  }
}

.v-treeview-item.v-list-item {
  border-radius: var(--prima-border-radius-small) !important;
}

.no-hover .v-treeview-item.v-list-item:hover > .v-list-item__overlay {
  opacity: 0 !important;
}

/* .document-folder::after {
  content: '//' !important;
  opacity: 1 !important;
  background-color: transparent !important;
  font-weight: 700;
  font-size: 1.1rem;
  padding-top: 10px !important;
  padding-left: 0px !important;
  color: #67b346 !important;

} */

.document-folder-menu::after {
  content: '//' !important;
  opacity: 1 !important;
  background-color: transparent !important;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 1px !important;
  color: #67b346 !important;
  position: absolute;
}

.document-folder-menu-sm::after {
  content: '//' !important;
  opacity: 1 !important;
  background-color: transparent !important;
  font-weight: 700;
  font-size: 0.9rem;
  padding-left: 0px !important;
  color: #67b346 !important;
  position: absolute;
}

.icon-btn {
  min-width: 0 !important;
}

.icon-medium {
  font-size: 1.5rem !important;
}

.icon-small {
  font-size: 16px !important;
}

.v-text-field {
  margin-top: 0px;
}

.CodeMirror-scroll {
  text-align: left !important;
}

.modal-scroll-content {
  min-height: min(50vh, 35rem);
  max-height: calc(100vh - 30rem);
  overflow-y: auto;
}

.editor-toolbar {
  text-align: left !important;
}

.markdown-container, .html-container {
  overflow-wrap: break-word;
  hyphens: auto;
  // white-space: pre-line;

  p {
    margin-bottom: 8px; // half of vuetify's default
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-bottom: 8px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    font-weight: 600;
  }
}

.markdown-container p:has(> strong) {
  margin-bottom: 0 !important;
}

.v-toolbar__content {
  padding: 0 !important;
}

.v-toolbar__extension > .v-tabs {
  margin-bottom: 0;
  margin-top: 0;
}

.primary-low-opacity {
  position: relative;

  &::before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.12;
    // background-color: rgba(0, 20, 137, 0.12) !important;
    background-color: rgb(var(--v-theme-primary)) !important;
  }
}

.word-break-all {
  word-break: break-all;
}

.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: initial !important;
}
.v-field--disabled {
  pointer-events: none !important;
  opacity: 0.6;
}

dl.inline-flex {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%; /* set the container width*/
  overflow: visible;
}
dl.inline-flex dt {
  flex: 0 0 30%;
  text-overflow: ellipsis;
  overflow: hidden;
}
dl.inline-flex dd {
  flex: 0 0 70%;
  margin-left: auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.textarea-grow-wrap {
  display: grid;

  & > .replicated-box {
    white-space: pre-line;
    visibility: hidden;

    /* Identical styling required!! */
    padding: 0 12px;
    margin-top: 6px;
    font: inherit;
    line-height: 1.75rem;
    word-break: break-word;
    // min-height: 32px;

    /* Place on top of each other */
    // grid-area: 1 / 1 / 2 / 2;
    grid-area: 1 / 1 / 2 / 2;

    /* color: red;
    border: 1px solid red; */
  }

  & textarea {
    white-space: pre-line;
    resize: none;
    overflow: hidden;
    word-break: break-word;
  }

  & > .v-textarea,
  & textarea {
    grid-area: 1 / 1 / 2 / 2;
  }

  & .v-input__slot {
    transition: none !important;
    transition-duration: 0 !important;
  }
}

.v-treeview-node__toggle {
  font-size: 1.25rem !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}

.sheet-border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
}

.sheet-border-active {
  border: 1px solid rgba(var(--v-theme-primary)) !important;
  border-radius: 4px;
}

.v-btn--contained,
.v-btn--is-elevated {
  box-shadow: none;
}

.planit-alert {
  margin-left: auto;
  margin-right: auto;
  // font-size: 1.15rem;
  // margin-bottom: 0.5rem !important;
}

// .v-menu {
//   z-index: 100 !important;
// }

.v-menu__content {
  z-index: 999 !important;
}

.document-header-background {
  background-color: #d2d2d2 !important;
  transition: 0s !important;
}

.v-tab {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  text-transform: none !important;

  &:not(.v-tab-item--selected) {
    color: rgba(0, 0, 0, 0.9) !important;
  }
}

.btn-multiline {
  flex-direction: column !important;
  letter-spacing: 0px !important;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.flex-basis-100 {
  flex-basis: 100%;
}

// Fixes clipped placeholder in multi autocomplete
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: unset !important;
}

.v-tooltip > .v-overlay__content {
  z-index: 200;
  background: #464646 !important;
  padding: 4px 8px !important;
  font-weight: 500;
  opacity: 1 !important;
  max-width: 40rem !important;
}

// Otherwise search wouldnt be placed on top of other stuff in some cases
.v-app-bar.v-app-bar--fixed {
  z-index: 200 !important;
}

.search-input-icon-fix {
  .v-input__prepend-inner .v-icon {
    font-size: 20px;
  }

  .v-text-field__slot {
    margin-left: 4px;
  }

}

.search-input-full-size {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  // border-radius: 0;

  z-index: 500;
}

.shepherd-button {
  background: unset;
  padding: 0 12px;
}

.shephard-planit-tour.shepherd-element {
  border-radius: var(--prima-border-radius);
  box-shadow: var(--prima-dialog-box-shadow) !important;
}

.shephard-planit-tour {
  font-family: var(--prima-default-font) !important;

  h1,
  h2,
  h3.shepherd-title,
  h4,
  h5,
  h6,
  label {
    font-weight: 600;
  }

  h3.shepherd-title {
    font-size: 200%;
  }

  h4 {
    font-size: 150%;
  }

  .primary {
    background-color: rgb(var(--v-theme-primary)) !important;
  }

  .shepherd-header {
    border-top-left-radius: var(--prima-border-radius) !important;
    border-top-right-radius: var(--prima-border-radius) !important;
  }

  .shepherd-header, .shepherd-has-title .shepherd-content .shepherd-header {
    background-color: #fff !important;
    justify-content: flex-start;
  }

  .shepherd-cancel-icon {
    color: rgb(var(--v-theme-primary)) !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;

    margin-left: auto !important;

    font-size: 36px !important;
  }

  .shepherd-title {
    background: $primary-gradient !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    color: transparent !important;

    display: inline-block !important;
    flex: unset !important;

    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .shepherd-header, .shepherd-text {
    padding: 0 1.5rem !important;
  }

  .shepherd-text {
    padding-bottom: 1.5rem !important;
    font-size: 1.15rem;
  }

  .shepherd-footer {
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem 1.5rem;

    .shepherd-button:last-child {
      margin-right: 0;
    }

    .shepherd-progress {
      font-size: .8rem;
    }
  }

}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.ribbon {
  $default-right: -3px;
  $default-top: 10px;
  $default-color: #2ca7d8;
  $default-width: 32px;
  $default-height: 36px;

  z-index: 20;

  position: fixed;
  right: var(--right, $default-right);
  top: var(--top, $default-top);

  filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

  > .content {
    color: white;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: var(--color, $default-color) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
    // padding: 8px 2px 4px;


    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

    // width: var(--width, $default-width);
    // min-height: var(--height, $default-height);

    transition: clip-path 1s, padding 1s, background 1s;
  }

  &.slant-up > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
  }

  &.slant-down > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
  }

  &.down > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
  }

  &.up > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
  }

  &.check > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
  }

  &.left > .content {
    // clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
    clip-path: polygon(100% 0, 100% 100%, 8px 100%, 0 50%, 8px 0);
  }

}


.prima-banner {
  box-shadow: 1px 1px 15px -3px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom right, #000e5eff 0%, #00378999 100%);
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
}

/* .prima-banner {
  background: linear-gradient(to bottom right, #00000033 0%, #00000000 100%);
} */

.prima-blue-background {
  background-size: 100% auto;

  position: relative;

  &::after {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;

    background-image: $primary-dark-gradient;
    mix-blend-mode: multiply;
  }

  & > .prima-blue-content {
    position: relative;
    z-index: 10;
  }
}

.prima-blue-background-support {
  background-image: url('../../public/blue_background_images/red_phone_small_wide.webp');
  background-position: center;
}

.blueprint-header {
  // box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.3);
  // background: #fff;
  margin-bottom: 1rem;
}

.background-none {
  background: none !important;
}

.blueprint-editor-card {
  border-radius: var(--prima-border-radius-small);
  background-color: #fff;
  box-shadow: 0px 2px 6px -3px rgb(0 0 0 / 30%);
  // border: 1px solid rgba(0, 0, 0, 0.1);
}

.blueprint-editor-card-box-shadow {
  box-shadow: 0px 2px 6px -3px rgb(0 0 0 / 30%);
  // border: 1px solid rgba(0, 0, 0, 0.1);
}

.blueprint-editor-card.v-select > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}

// .v-navigation-drawer--left {
//   border-right-width: 0 !important;
// }

.mx-blueprint-editor {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $form-color;
}

.theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: $form-color-light;
} */

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: var(--prima-box-shadow);
}

#planit-footer {
  padding: 0.5rem 0;
  // background: #f0f0f0;
  // background: #a3a3a326;
  background-color: transparent;

  &.footer-dark {
    background: transparent;
    color: #fff !important;
  }
}

.v-btn:not(.v-tab) {
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.06em;
}

.v-btn.v-btn--size-small {
  font-size: 0.9rem !important;
  letter-spacing: unset !important;
}

.v-btn.v-btn--size-x-small {
  font-size: 0.8rem !important;
  letter-spacing: unset !important;
}

.btn-alt {
  text-transform: capitalize;
  font-size: 11pt !important;
  font-weight: 600;
}

.btn-alt.btn-small {
  font-size: 9pt !important;
  letter-spacing: unset !important;
}

.single-column-form {
  width: 50%;
  min-width: 40rem;
}

.document-language-select {
  &.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner {
    margin-top: 14px !important; // ( 48 - 20(height) ) / 2
  }

  &.v-text-field > .v-input__control > .v-input__slot:before {
    display: none;
  }

}

.orange-gradient {
  --bg-gradient-start: var(--v-planitOrange-lighten1);
  --bg-gradient-end: var(--v-planitOrange-base);
}

.orange-gradient-lighten1 {
  --bg-gradient-start: var(--v-planitOrange-lighten2);
  --bg-gradient-end: var(--v-planitOrange-lighten1);
}

.bg-gradient {
  background: linear-gradient(to bottom right, var(--bg-gradient-start) 0%, var(--bg-gradient-end) 100%);
  border-color: linear-gradient(to bottom right, var(--bg-gradient-start) 0%, var(--bg-gradient-end) 100%);
}

.primary-gradient {
  background: $primary-gradient;
}

.primary-dark-gradient {
  background: $primary-dark-gradient;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;

  display: inline-block;
  flex: unset;
}

.card-box-shadow {
  box-shadow: var(--prima-box-shadow);

  &.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: var(--prima-box-shadow);
  }

}

.prima-dialog-box-shadow {
  box-shadow: var(--prima-dialog-box-shadow);
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.border-radius, .v-application .rounded, .dashboard-flat .dashboard-section {
  border-radius: var(--prima-border-radius) !important;
}

.border-top-right-radius {
  border-top-right-radius: var(--prima-border-radius) !important;
}

.border-top-left-radius {
  border-top-left-radius: var(--prima-border-radius) !important;
}

.border-bottom-right-radius {
  border-bottom-right-radius: var(--prima-border-radius) !important;
}

.border-bottom-left-radius {
  border-bottom-left-radius: var(--prima-border-radius) !important;
}

.border-radius-sm {
  border-radius: var(--prima-border-radius-small) !important;
}

.border-grey--darken-4 {
  border-color: #212121 !important;
}

.border-white {
  border-color: #fff !important;
}

.login-card-background {
  background-image: url(../../public/Login_PRIMA.svg);
  background-position: center bottom;
  background-size: cover;
}

dl {
  display: flex;
  flex-flow: row wrap;
}
dt {
  flex-basis: 20%;
  text-align: right;
}
dd {
  flex-basis: 100%;
  flex-grow: 1;
  font-weight: 600;
}

.v-stepper__label, .v-stepper-vertical-item__title {
  font-weight: 600;
}

.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem;
}

.bg-license-select {
  background-color: #e7e9ef;

  & a:hover {
    cursor: pointer;
  }
}

.bg-table-striped {
  background-color: #e7e9ef;
}

.checkerboard-background {
  --checker-color1: #ccc;
  --checker-color2: #888;
  background-color: var(--checker-color1);

  background-image:
    linear-gradient(45deg, var(--checker-color2) 25%, transparent 25%, transparent 75%, var(--checker-color2) 75%, var(--checker-color2)),
    linear-gradient(45deg, var(--checker-color2) 25%, transparent 25%, transparent 75%, var(--checker-color2) 75%, var(--checker-color2));

  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  background-repeat: repeat;
}

$multi-answer-colors: (
  #3498db,
  #1a743f,
  #5b3597,
  #f39c12,
  #e74c3c
);

@for $i from 1 through length($multi-answer-colors) {
  .multi-answer-border-#{$i} {
    border-left: 2px solid nth($multi-answer-colors, $i);
  }

  .multi-answer-background-#{$i}, .bg-multi-answer-background-#{$i} {
    background-color: rgba(nth($multi-answer-colors, $i), 0.05);
  }

  .multi-answer-background-full-#{$i}, .bg-multi-answer-background-full-#{$i} {
    background-color: nth($multi-answer-colors, $i) !important;
  }

  .multi-answer-color-#{$i} {
    color: nth($multi-answer-colors, $i);
  }
}

$multi-answer-grey-base: #ddd;

@for $i from 1 through 5 {
  .multi-answer-border-grey-#{$i} {
    border-left: 2px solid darken($multi-answer-grey-base, $i * 15%);
    border-radius: 0 4px 4px 0;
    background-color: #00000008;
  }

  .multi-answer-background-grey-#{$i} {
    background-color: darken($multi-answer-grey-base, $i * 15%);
  }

  .multi-answer-color-grey-#{$i} {
    color: darken($multi-answer-grey-base, $i * 15%);
  }
}

.table-fixed-container {
  & table { table-layout: fixed; }
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.v-menu__content .v-subheader, .v-list-item--disabled {
  font-size: 1rem !important;
  padding: 0 16px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, .7) !important;
  background-color: rgba(0, 0, 0, .05) !important;
}

.v-toolbar.v-app-bar:not(.bg-transparent) > .v-toolbar__content {
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5) !important;
}

/* .v-input.v-select.v-autocomplete.v-select--chips.v-select--is-multi:not(.v-input--dense) .v-select__slot {
  padding: 8px 0;
} */

.v-select.v-input--dense .v-chip {
  margin: 4px !important;
}

.card-border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white\/60 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.answer-full-width-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.answer-full-width-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.answer-full-width-table.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: unset;
}

.answer-full-width-table {
  & table {
    table-layout: fixed;
    width: 100%;
  }

  & th, & td {
    height: unset !important;
    min-height: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  & tr th:first-child, & tr td:first-child {
    padding-left: 1.5rem !important;
  }

  & tr th:last-child, & tr td:last-child {
    padding-right: 1.5rem !important;
  }

  & td {
    vertical-align: baseline;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  & tr.no-border td, & tr.no-border th {
    border-top: none !important;
    border-bottom: none !important;
  }

  & th.th-level-1 {
    font-weight: 600;
    font-size: 120%;
  }

  & th.th-level-2 {
    font-weight: 600;
  }

  & th.th-level-3 {
    font-weight: 400;
    font-size: 100% !important;
  }

  & th.th-level-4 {
    font-weight: 400;
    font-size: 75% !important;
  }

  & th.th-level-5 {
    font-weight: 400;
    font-size: 75% !important;
  }

  & th.th-level-6 {
    font-weight: 400;
    font-size: 75% !important;
  }

  &.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: inherit;
  }

  &.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(.tr-level-1):not(.tr-level-2):hover:nth-child(even):not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  tr:not(.tr-level-1):not(.tr-level-2):nth-child(even),
  tr:not(.tr-level-1):not(.tr-level-2):hover:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.tr-level-2:hover:nth-child(even):not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  tr.tr-level-2:nth-child(even),
  tr.tr-level-2:hover:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.025);
  }

}

.v-input__icon.v-input__icon--clear > .v-icon {
  font-size: 20px;
}

.v-combobox {
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }

}

// wrapping chips would otherwise overlap
.v-autocomplete__selection {
  height: unset !important;
}

.v-autocomplete__selection .v-chip.v-chip--size-default {
  height: unset !important;
  min-height: 32px !important;
  text-wrap: wrap;
}

// Missing classes in old Vuetify version
.ga-0 {
  gap: 0;
}
.ga-1 {
  gap: 4px;
}
.ga-2 {
  gap: 8px;
}
.ga-3 {
  gap: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer\:hover:hover {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.align-super {
  vertical-align: super;
}

.select-none {
  user-select: none;
}

.persistent-placeholder .v-select.v-input--is-dirty ::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-style: italic;
}

.anchor-target::before {
  content: '';
  display: block;
  height:      75px;
  margin-top: -75px;
  visibility: hidden;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

.theme--light.v-data-table {
  background: transparent !important;
}

.border-outlined {
  border: 1px solid rgba(5, 66, 113, 0.2);
  border-radius: var(--prima-border-radius-small);
}

.text-small {
  font-size: 0.75rem;
}

.success-class {
  color: var(--v-success-base) !important;
}

.v-treeview .v-list-item--density-default.v-list-item--one-line {
  padding-top: 0;
  padding-bottom: 0;
}

.fa-fw {
  text-align: center !important;
  width: 1.25em !important;
}

.v-picker-title > .v-time-picker__title {
  text-transform: none;
  font-size: 1rem;
}

.v-field--variant-solo {
  box-shadow: var(--prima-box-shadow) !important;
}

.v-btn--disabled.bg-primary {
  filter: grayscale(1);
}

.v-autocomplete__content .v-list-item--active,
.v-select__content .v-list-item--active {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.disabled-full-opacity .v-selection-control--disabled {
  opacity: 1 !important;
}
